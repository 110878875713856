import * as React from 'react';
import Layout from '../components/layout';

const NotFoundPage = () => {
  return (
    <Layout>
      <div>Not found</div>
    </Layout>
  );
};

export default NotFoundPage;
